import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Environment } from '@app/core/_shared/interfaces/environment/enviroment.interface';
import { finalize, share, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

declare const environment: Environment;

interface Token {
  token: string;
  status: number;
  error: string;
}

export interface CodeChallenge {
  raw: string;
  method: string;
}

interface Authorize {
  email: string;
  error: string;
  id: string;
  name: string;
  organization: string;
  organizationName: string;
  status: number;
}

interface Application {
  code: number;
  result: {
    account: string;
    candidate: string;
    error: string;
    person: string;
    position: string;
    status: number;
  };
}

interface Tokens {
  access_token: string;
  id_token: string
  refresh_token: string;
  expires_in: number
  refresh_expires_in: number;
  session_state: string;
}

@Injectable({ providedIn: 'root' })
export class CallbackService {
  private readonly url: string = environment.url;
  private readonly redirectUri: string = environment.redirect_uri;
  private readonly keycloak_url: string = environment.keycloak_url;
  cachedApplicationObservable: Observable<Application> = null;

  constructor(private http: HttpClient) {}

  /**
   * Takes callback code and returns a token
   * @param code a token that Identity sends via a callback URL
   * @returns an object with a valid token
   */
  getToken(code: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        redirectURI: this.redirectUri,
        code: code,
      }),
      withCredentials: true,
    };
    return this.http.get<Token>(`${this.url}/authorize/code`, httpOptions);
  }

  /**
   * Takes the token and returns user infos and organization ID
   * @param token a valid JWT token
   * @returns an object with user infos and organization ID
   */
  getAuthorization(token: string, state: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        State: state,
        observe: 'response'
      }),
      withCredentials: true,
    };

    return this.http.get<any>(`${this.url}/authorize`, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token}`).set('State', state),
      observe: 'response',
      withCredentials: true,
    });
  }

  /**
   * Takes the code challenge for pkce authentication flow
   * @returns an object with code challenge
  */
  getCodeChallenge() {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get<CodeChallenge>(`${this.url}/authorize/challenge`, httpOptions);
  }

  /**
   * Takes the state token and returns user infos (e.g., position and person ID)
   * @param state a token that Identity sends via a callback URL
   * @returns an object with account, candidate, position and person ID
   */
  getApplication(state: string) {
    let observable: Observable<any>;
    if (this.cachedApplicationObservable) {
      observable = this.cachedApplicationObservable;
    } else {
      this.cachedApplicationObservable =  this.http.post<Application>(
        `${this.url}/admission/application?t=${state}`,
        ''
      ).pipe(tap((res) => res ),share(),finalize(() => this.cachedApplicationObservable = null));
        observable = this.cachedApplicationObservable;
    }
    return observable;
  }

  //https://logintest.unico.io/auth/realms/unico-you/protocol/openid-connect/token
  /**
   * Takes callback code and codeVerifier to get access_token and refresh_token
   * @param code a token that Identity sends via a callback URL
   * @returns an object with access_token and refresh_token
   */
   getAccessToken() {
    const grantType = 'authorization_code';
    const clientId = 'unico-people';
    const redirectUri =  this.redirectUri;
    const code =  localStorage.getItem('code');
    const codeVerifier = localStorage.getItem('verifier');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };

    const params = `grant_type=${grantType}&client_id=${clientId}&redirect_uri=${redirectUri}&code=${code}&code_verifier=${codeVerifier}`;
    let body = new HttpParams({fromString: params.toString()});

    return this.http.post<Tokens>(`${this.keycloak_url}/auth/realms/unico-you/protocol/openid-connect/token`, body.toString(), httpOptions);
  }

}
