import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd, RouterOutlet, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { slideInAnimation } from './_shared/animations/animations';
import * as Sentry from "@sentry/angular-ivy";
import { Environment } from './core/_shared/interfaces/environment/enviroment.interface';
import { Title } from '@angular/platform-browser';
import { LaunchdarklyService } from '@acesso-io/launchdarkly-client-ts/lib/launchdarkly';

declare let ga: any;
declare let environment: Environment;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation],
})
@Sentry.TraceClassDecorator()
export class AppComponent implements OnInit {

  titleDefault = 'unico';

  constructor(
    public router: Router,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    private titleService: Title,
    private launchDarklyService: LaunchdarklyService,
  ) {
    const kind = localStorage.getItem('inviteKind');

    if (kind === 'collaborator') {
      document.documentElement.style.setProperty('--main-color', '#00a5ff');
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let child = this.activatedRoute.firstChild;
        while (child.firstChild) {
          child = child.firstChild;
          this.titleDefault = child.snapshot.data['title'] || 'Unico';
        }
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
        this.titleService.setTitle(this.titleDefault);
      }
    });
    const _ = this;
    window.addEventListener('offline', function () {
      _.toastr.warning(
        'Verifique sua rede de internet antes de prosseguir.',
        'Você está com problemas de conexão!'
      );
    });

    window.addEventListener('online', function () {
      _.toastr.success(
        'Sua conexão com a internet voltou.',
        'Você está online novamente! :)'
      );
    });
  }

  ngOnInit(): void {
    this.configureSupportTool();
    this.elementRef.nativeElement.removeAttribute('ng-version');
  }

  private configureSupportTool() {
    if (this.evaluateFFWhatsAppSupport()) {
      const whatsappUrl = 'https://api.whatsapp.com/send/?phone=551150393188&text=Oi%2C%20estou%20em%20processo%20de%20admiss%C3%A3o%20e%20preciso%20de%20ajuda.&type=phone_number&app_absent=0';
      const link = `<a title="WhatsApp" href="${whatsappUrl}" target="_blank"><img src="assets/images/core/whatsapp.svg" loading="lazy" alt="WhatsApp Logo" style="position: fixed; bottom: 20px; right: 12px; width: 65px; z-index: 1000;"></a>`;

      let divNode = document.createElement("div");
      divNode.id = 'whatsapp';
      divNode.innerHTML = link;

      document.body.appendChild(divNode);
    } else {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = environment?.snippet_zendesk || '';
      script.id = 'ze-snippet';
      script.async = true;

      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }

  evaluateFFWhatsAppSupport(): boolean {
    return this.launchDarklyService?.evaluateBoolean({ 
      flagKey: 'people-admissions-enable-whatsapp-support'
    })
  }

  onDeactivate() {
    this.renderer.setProperty(document.body, 'scrollTop', 0);
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }
}
