import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

declare let environment;
declare global {
    interface Window { Cypress: any; msw: any }
}

@Injectable()
export class AuthGuardService implements CanActivate {
    private identity_url: string = environment.identity_url;
    private base_url: string = environment.base_url;

    constructor(private router: Router, private auth: AuthService) {}

    canActivate() {
        if (window.msw) return true;

        if (this.auth.loggedIn()) {
            return true;
        } else {
            this.router.navigate(['/nao-autorizado']);
            return false;
        }
    }
}
