import { Injectable } from '@angular/core';
import { CallbackService, CodeChallenge } from '@app/modules/callback/callback.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LaunchdarklyService } from '@acesso-io/launchdarkly-client-ts/lib/launchdarkly';
import { StateToken } from '@app/_shared/models/state-token.model';
import * as ld from 'launchdarkly-js-client-sdk';
import { ContaUnicoEvaluateConfig } from '@app/_shared/models/conta-unico.model';
import { JWTClaimOverrideName } from './jwt.interface';

declare let environment: any;

@Injectable()
export class AuthService {
    private identity_url: string = environment.identity_url;
    private base_url: string = environment.base_url;
    private url: string = environment.url;


    constructor(
      private http: HttpClient,
      public jwtHelper: JwtHelperService,
      public callbackService: CallbackService,
      private launchDarklyService: LaunchdarklyService,
    ) {}

    loggedIn() {
        const token: string = localStorage.getItem('token');
        return token != null && !this.jwtHelper.isTokenExpired(token);
    }

    logoutApi(): Observable<any> {
      return this.http.get<any>(`${this.url}/logout`);
    }

    logout() {
      this.logoutApi().subscribe(() => {
        const state = localStorage.getItem('state');
        localStorage.clear();

        const ft = this.launchDarklyService.evaluateBoolean({ flagKey: "people-admissions-bugfix-storage-keys" });

        if(!ft) {
          localStorage.setItem('state', state);
          window.location.href = `${this.identity_url}/signout?redirect_uri=${this.base_url}/logout`;
          return;
        }

        window.location.href = `${this.identity_url}/signout?redirect_uri=${this.base_url}/profile/intro/${state}`;
      })
    }

    async startPKCEAuthenticationFlow(redirect_uri: string, loginPath: string, state: string): Promise<string> {
      let identityFinalURL = this.getIdentityUrl(loginPath, state, redirect_uri, "token")

      const match = this.launchDarklyService?.evaluateBoolean({flagKey: "people_authorization_code_with_pkce"});
      if(match) {
        const codeChallenge = await this.callbackService.getCodeChallenge().toPromise().catch((err) => {
          console.error(err)
          return {} as CodeChallenge
        })
        if (codeChallenge.raw && codeChallenge.method) {
          identityFinalURL =  this.getIdentityUrl(loginPath, state, redirect_uri, "code")

          let pkceQueryParams = `&code_challenge=${codeChallenge.raw}&code_challenge_method=${codeChallenge.method}`
          identityFinalURL = `${identityFinalURL}${pkceQueryParams}`
        }
      }

      return identityFinalURL
    }

    async startContaUnicoFlow(redirect_uri: string, conta_unico_client_id: string, conta_unico_url: string, state: string): Promise<ContaUnicoEvaluateConfig> {
      let authBrowserUrl = ""

      const positionAuthNByContaUnicoEnabled = localStorage.getItem("position_authn_by_conta_unico_enabled");
      const match = await this.enabledContaUnico();
      if(match && positionAuthNByContaUnicoEnabled) {
        localStorage.setItem("originContaUnico", "y")
        const codeChallenge = await this.callbackService.getCodeChallenge().toPromise()
          .catch((err) => {
            console.error(err)
            return {} as CodeChallenge
          })

        if (
          codeChallenge.raw && codeChallenge.method &&
          conta_unico_url && conta_unico_client_id &&
          redirect_uri && state
        ) {
          const pkceParams = `&code_challenge=${codeChallenge.raw}&code_challenge_method=${codeChallenge.method}`
          authBrowserUrl = `${conta_unico_url}?client_id=${conta_unico_client_id}&redirect_uri=${redirect_uri}&state=${state}&scope=profile openid&response_type=code${pkceParams}`

          return {enabled: true, url: authBrowserUrl} as ContaUnicoEvaluateConfig
        }
      }

      return {enabled: false, url: authBrowserUrl} as ContaUnicoEvaluateConfig
    }

    async enabledContaUnico(): Promise<boolean> {
      const position = localStorage.getItem('originPositionId');
      const organization = localStorage.getItem('originOrganizationId');

      let context: ld.LDContext;

      if (organization) {
        context = {
          kind: 'multi',
          organization: {
            key: organization,
          },
          user: {
            key: position,
            position,
            appName: 'form',
          },
        } as ld.LDMultiKindContext;
      } else {
        context = {
          kind: 'user',
          key: '00000000-0000-0000-0000-000000000000',
          appName: 'form',
        } as ld.LDSingleKindContext;
      }

      await this.launchDarklyService.overrideContext({ context });

      return this.launchDarklyService?.evaluateBoolean({ flagKey: "people-authorization-code-with-conta-unico" });
    }

    getIdentityUrl(loginPath: string, state: string, redirectURI: string, responseType: string): string{
      return `${this.identity_url}/${loginPath}?scope=rh:*&state=${state}&redirect_uri=${redirectURI}&response_type=${responseType}&client_id=8aead3e6-b295-4f77-b314-86b975ee3ae2&tenant_id=7c15017b-3c9f-45e9-9833-53fcc82247a8`
    }

    setOrganizationInLocalstorage(key: string, token: string) {
      const payload = this.jwtHelper.decodeToken(token) as StateToken
      if(payload?.org) {
        localStorage.setItem(key,  payload.org);
      }
    }

    setJWTClaimInLocalstorage(claims: Array<JWTClaimOverrideName>, token: string) {
      const payload = this.jwtHelper.decodeToken(token) as StateToken

      if (payload) {
        for (let claim of claims) {
          if(payload[claim.Name]) {
            localStorage.setItem(claim.OverrideName,  payload[claim.Name]);
          }
          if(payload[claim.Name]) {
            localStorage.setItem(claim.OverrideName,  payload[claim.Name]);
          }
        }
      }
    }

    definePreSettings(token) {
      const claims = [
        { Name: "org", OverrideName: "originOrganizationId" },
        { Name: "pos", OverrideName: "originPositionId" }
      ] as Array<JWTClaimOverrideName>

      this.setJWTClaimInLocalstorage(claims, token);
      localStorage.setItem("originAppName", "form");
    }
}
