(function ($) {
  'use strict';
  let environment = {
    production: false,
    url: 'http://svc1.acessorh.localhost',
    cdn_url: 'http://cdn1.acessorh.localhost',
    identity_url: 'http://identity.acesso.localhost',
    base_url: 'http://app.acessorh.localhost',
    benefits_url: 'http://form-benefits.acessorh.localhost',
    flipt_url: 'http://flipt.acessorh.localhost/api/v1',
    redirect_uri: 'http://app.acessorh.localhost/callback',
    segmentApiKey: 'aJSx6ITwDTBcFEq6je022Y0UrC52Khm1',
    keycloak_url: 'https://logintest.unico.io',
    sign_id: 'DB8EF2DD966F2CE9F3237F1A1746143B346393D373C7268FFC32759DE06597EA',
    preview: 'false',
    snippet_zendesk: 'https://static.zdassets.com/ekr/snippet.js?key=e5d2743c-b85c-4085-9c9a-7ad36458393a', // temp snippet
    launchDarklyClientId: '63ea5db5e87179139977b661',
    app_version: '',
    sentry_enabled: true,
    sentry_dsn: 'https://dfe4667da7ae47369e201af9755f3c6e@o939397.ingest.sentry.io/4505229455720448',
    sentry_ignore_errors: null,
    conta_unico_client_id: 'people',
    conta_unico_url: 'http://localhost:8080/realms/custom/protocol/openid-connect/auth',
    mixpanelToken: 'f4069d9b550ea08e34089810c2abd5be',
  };

  if (typeof define === 'function' && define.amd) {
    define(function () {
      return environment;
    });
  } else if (typeof module === 'object' && module.exports) {
    module.exports = environment;
  } else {
    $.environment = environment;
  }
})(window);
