import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './core/_guards/auth/auth-guard.service';

const appRoutes: Routes = [
  {
    path: 'callback',
    loadChildren: () =>
      import('./modules/callback/callback.module').then(
        (m) => m.CallbackModule
      ),
  },
  {
    path: '',
    redirectTo: 'documents',
    pathMatch: 'full',
    data: { title: 'Página de documentos' },
  },
  {
    path: 'inicio',
    loadChildren: () =>
      import('./modules/onboarding/onboarding.module').then(
        (m) => m.OnboardingModule
      ),
      data: { title: 'Página de inicio' }
  },
  {
    path: 'profile/intro/:token',
    loadChildren: () =>
      import('./modules/invite/invite.module').then((m) => m.InviteModule),
  },
  {
    path: 'completed',
    loadChildren: () =>
      import('./modules/completed/completed.module').then(
        (m) => m.CompletedModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'completed-collaborator',
    loadChildren: () =>
      import('./modules/completed-collaborator/completed.module').then(
        (m) => m.CompletedCollaboratorModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'document',
    loadChildren: () =>
      import('./modules/documents/documents.module').then(
        (m) => m.DocumentsModule
      ),
    canActivate: [AuthGuardService],
    data: { title: 'Página de documentos' },
  },
  {
    path: 'documents',
    loadChildren: () =>
      import('./modules/listing/listing.module').then((m) => m.ListingModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'success',
    loadChildren: () =>
      import('./modules/success/success.module').then((m) => m.SuccessModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./modules/logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: 'excluded',
    loadChildren: () =>
      import('./modules/excluded/excluded.module').then((m) => m.ExcludedModule),
  },
  {
    path: 'declined',
    loadChildren: () =>
      import('./modules/declined/declined.module').then((m) => m.DeclinedModule),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./modules/not-found/not-found.module').then(
        (m) => m.NotFoundModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
