
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from "@sentry/angular-ivy";

import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { ToastrModule } from 'ngx-toastr';

import { JwtModule } from '@auth0/angular-jwt';
import { AppRoutingModule } from './app.routing.module';

import { AppComponent } from './app.component';

import { environment as env } from '../environments/environment';
import { CustomSerializer, reducers } from './core/_store';
declare const environment: Environment;
// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { LaunchdarklyService } from '@acesso-io/launchdarkly-client-ts/lib/launchdarkly';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SegmentModule } from 'ngx-segment-analytics';
import { GlobalErrorHandler } from './_shared/services/error/error.service';
import { TokenInterceptor } from './_shared/services/interceptors/interceptor.service';
import initializeAppFactory from './_shared/services/app-initializer/app-initializer.factory';
import { AuthGuardService } from './core/_guards/auth/auth-guard.service';
import { AuthService } from './core/_guards/auth/auth.service';
import { Environment } from './core/_shared/interfaces/environment/enviroment.interface';

export function tokenGetter() {
  return localStorage.getItem('token');
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [] : [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    !env.production ? StoreDevtoolsModule.instrument() : [],
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatSnackBarModule,
    HttpClientXsrfModule.withOptions({
      headerName: 'Acesso-Csrf-Token',
    }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center'
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    SegmentModule.forRoot({ apiKey: '', debug: false, loadOnInitialization: false })
  ],
  providers: [
    AuthService,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: LaunchdarklyService, useFactory: () => new LaunchdarklyService(environment.launchDarklyClientId)},
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [LaunchdarklyService]
    },
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
