declare let require: any;
import * as env from '../assets/js/env5.59210070f30bd6764fdf.js';

export const environment = {
    production: true,
    url: env.url,
    cdn_url: env.cdn_url,
    identity_url: env.identity_url,
    base_url: env.base_url,
    preview: env.preview,
    snippet_zendesk: env.snippet_zendesk,
    app_version: env.app_version,
    sentry_enabled: env.sentry_enabled,
    sentry_dsn: env.sentry_dsn,
    sentry_ignore_errors: env.sentry_ignore_errors,
    sentry_ignore_status_code_errors: env.sentry_ignore_status_code_errors,
    conta_unico_client_id: env.conta_unico_client_id,
    conta_unico_url: env.conta_unico_url,
    mixpanelToken: '74f711481296bc88e62c883781fc60b5',
};
